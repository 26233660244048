export interface IItem {
  id: string;
  title: string;
  price: number;
  description: string;
  src: string[];
}
 
const purimItems: IItem[] = [
  {
    id: "uJqRlraFxC",
    title: "מלכת הקרח",
    description: `התחפושת כוללת שמלה 
    החלק העליון דמוי מחוך
    והחלק התחתון טול שכבות 
    גלימה עם צווארון מפואר עומד 
    וכתר מעוצב`,
    price: 500,
    src: ['/items/snowQueen/1.jpeg', '/items/snowQueen/2.jpeg', '/items/snowQueen/3.jpeg', '/items/snowQueen/4.jpeg', '/items/snowQueen/5.jpeg']
  },
  {
    id: "uJwYtratCC",
    title: "בת הים",
    description: `התחפושת כוללת טופ מעוצב עם שרשרת פנינים וצדף 
    וחצאית פייטים ובחלק התחתון טול 
    כתר מעוצב 
    קילשון מעוצב `,
    price: 500,
    src: ['/items/littleMermaid/1.jpeg', '/items/littleMermaid/2.jpeg', '/items/littleMermaid/3.jpeg', '/items/littleMermaid/4.jpeg',]
  },
  {
    id: "pofDuufDSA",
    title: "פיית היער",
    description: `התחפושת כוללת חולצה
     חצאית טול שכבות ארוכה 
    חגורה פרחונית
     כתר ושרביט פרחוני 
     כנפיים`,
    price: 470,
    src: ['/items/forestFairy/1.jpeg', '/items/forestFairy/2.jpeg',]
  },
  {
    id: "jgdTReFdE",
    title: "גלידה",
    description: `התחפושת כוללת חולצה מעוצבת עם חצאית וקשת גלידות `,
    price: 430,
    src: ['/items/icecream/1.jpeg', '/items/icecream/2.jpeg', '/items/icecream/3.jpeg',]
  },
  {
    id: "jgfTeeFdE",
    title: "היפית",
    description: `התחפושת כוללת חולצה מכנס וקשת פפיון `,
    price: 350,
    src: ['/items/hippie/1.jpeg', '/items/hippie/2.jpeg',]
  },
  {
    id: "tyFSEefgE",
    title: "רוקיסט",
    description: `התחפושת כוללת גופית רשת
     ג׳קט מעוצב
      ומכנס עור עם חגורה`,
    price: 370,
    src: ['/items/rockist/1.jpeg', '/items/rockist/2.jpeg']
  },
  {
    id: "kyTjfdorCC",
    title: "קומיקס נערות",
    description: `תחפושת קומיקס לנערות 
    הכוללת מכנס פדלפון וחולצה כתף אחת`,
    price: 470,
    src: ['/items/comics/1.jpeg', '/items/comics/2.jpeg', '/items/comics/3.jpeg',]
  },
  {
    id: "kyRTfffd4j",
    title: "קומיקס ילדים",
    description: `התחפושת כוללת חולצה כתף אחת עם מלמלה 
    חצאית בד קומיקס בשילוב טול ופפיונים 
    ותיק צד `,
    price: 350,
    src: ['/items/comicsChild/1.jpeg', '/items/comicsChild/2.jpeg', '/items/comicsChild/3.jpeg']
  },
  {
    id: "kfERgTfiE",
    title: "קומיקס נערות צנועה",
    description: `התחפושת כוללת חצאית וחולצה`,
    price: 470,
    src: ['/items/comicsTeen/1.jpeg', '/items/comicsTeen/2.jpeg']
  },
  {
    id: "kyRTfftyiE",
    title: "ברבי",
    description: `התחפושת כוללת דמוי מחוך עם שרוולים נשלפים 
    וחצאית טוטו קשירות נפוחה  `,
    price: 430,
    src: ['/items/barbie/1.jpeg', '/items/barbie/2.jpeg']
  },

  {
    id: "kyTdsaSCC",
    title: "אינדיאני",
    description: `התחפושת כוללת מכנס מעוצב פרנזים
    חולצה מעוצבת פרנזים
    ושרשרת נוצות 
    כתר נוצות`,
    price: 370,
    src: ['/items/indian/1.jpeg', '/items/indian/2.jpeg', '/items/indian/3.jpeg',]
  },
  {
    id: "kyRTfdssC",
    title: "אינדיאנית",
    description: `תחפושת כוללת חולצה מעוצבת נוצות 
    חצאית 
    שרשרת נוצות 
    וכתר נוצות מעוצב`,
    price: 370,
    src: ['/items/femaleIndian/1.jpeg', '/items/femaleIndian/2.jpeg']
  },
  {
    id: "jdsareRRE",
    title: "דרקון ילדים",
    description: `תחפושת כוללת מכנס וחולצה מעוצבים 
    אביזרים : כנפיים זנב ומסיכה `,
    price: 400,
    src: ['/items/dragonChild/1.jpeg', '/items/dragonChild/2.jpeg', '/items/dragonChild/3.jpeg', '/items/dragonChild/4.jpeg']
  },
  {
    id: "jdsfouiERE",
    title: "דרקון נערות",
    description: `תחפושת כוללת מכנס וחולצה מעוצבים 
    אביזרים : כנפיים זנב ומסיכה `,
    price: 500,
    src: ['/items/dragonTeen/1.jpeg', '/items/dragonTeen/2.jpeg', '/items/dragonTeen/3.jpeg','/items/dragonTeen/4.jpeg',]
  },
  {
    id: "hfRRifnsaF",
    title: "האריה שאהב תות",
    description: `תחפושת כוללת חולצה עם צווארון פרווה 
    מכנס עם זנב וחגורה 
    רעמה עם אוזניים `,
    price: 370,
    src: ['/items/strawLion/1.jpeg', '/items/strawLion/2.jpeg', '/items/strawLion/3.jpeg',]
  },
  {
    id: "hfRRifnnF",
    title: "תות",
    description: `תחפושת כוללת שמלה עם צווארון עלים וחותלות 
    קשת גבעול ועלים`,
    price: 350,
    src: ['/items/strawberry/1.jpeg', '/items/strawberry/2.jpeg',]
  },
  {
    id: "hyTddASF",
    title: "אשת הזאב",
    description: `תחפושת כוללת חצאית וגופיית קפוצ׳ון
    חותלות לידיים ולרגליים `,
    price: 500,
    src: ['/items/wolfGirl/1.jpeg', '/items/wolfGirl/2.jpeg', '/items/wolfGirl/3.jpeg', '/items/wolfGirl/4.jpeg', '/items/wolfGirl/5.jpeg',]
  },
  {
    id: "trEDasfAdF",
    title: "במבי",
    description: `תחפושת כוללת חצאית טול 
    חולצה וקשת מעוצבת`,
    price: 370,
    src: ['/items/bambi/1.jpeg', '/items/bambi/2.jpeg', '/items/bambi/3.jpeg', '/items/bambi/4.jpeg', '/items/bambi/5.jpeg',]
  },
];

const database: Record<string, IItem[]> = {
  purim: purimItems,
}


export default database;