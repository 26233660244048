import { ReactElement} from "react";
import {
  BrowserRouter, Route, Routes,
} from "react-router-dom";
import Application from "../Application/Application";



const Router = (): ReactElement => {
  return <>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Application />}/>
      </Routes>
    </BrowserRouter>
  </>
}

export default Router;