import { ReactElement } from "react";
import {  useNavigate } from 'react-router-dom';
import OrderDescription from "../../components/OrderDescription/OrderDescription";
import PageItem from "../../components/PageItem/PageItem";
import VerticalLine from "../../components/VerticalLine/VerticalLine";
import database from "../../database/items";
import styles from "./Page.module.css"

const Page = (): ReactElement => {
  const pageTitle = 'תחפושות לפורים';
  const navigate = useNavigate();
  const hadnleItemClick = (id: string) => {
    navigate(`/item/${id}`);
  }
  return <div className={styles.pageContainer}>
    <div className={styles.title}>{pageTitle}</div>
    <VerticalLine />
    <div className={styles.page}>
      {database['purim']?.map((item) => <div key={item.id} className={styles.itemContainer} onClick={() => hadnleItemClick(item.id)}><PageItem item={item} /> </div>)}
    </div>
    <VerticalLine />
    <OrderDescription/>
  </div>

}
export default Page;