import React, { ReactElement } from 'react';
import SmallContact from '../SmallContact/SmallContact';
import VerticalLine from '../VerticalLine/VerticalLine';
import styles from './OrderDescription.module.css'

const OrderDescription = (): ReactElement => {
  return <div className={styles.About}>
    <div className={styles.Title}> איך מזמינים</div>
    <div className={styles.subTitle}>
    <div>
      רוצים עיצוב משלכם שלא קיים באתר?
    </div>
    <div>
    ניתן לעצב עיצוב אישי במידה אישית לכל מה שרק תחלמו...
    </div>
    </div>
    <SmallContact/>
    <VerticalLine/>
    <div className={styles.SocialContainer}>
      <div>מוזמנים לעקוב אחריי ברשתות החברתיות</div>
      <div className={styles.Social}>
      <a href="https://www.facebook.com/Lior-Ben-Zikri-1859756111004889" target="_blank" rel="noreferrer"><img src="/facebook.png" alt="facebook" height="25px"/></a>
      <a href="https://instagram.com/lior_ben_zikri?utm_source=ig_profile_share&igshid=181fude79nfjg" target="_blank" rel="noreferrer"><img src="/instagram.png" alt="instagram" height="25px"/></a>
      </div>
    </div>
  </div>
}
export default OrderDescription;