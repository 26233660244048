import { ReactElement } from "react";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IItem } from "../../database/items";
import styles from './ItemCarousel.module.css'
interface IProps {
  item: IItem;
}
const ItemCarousel = (props: IProps): ReactElement => {
  const { item } = props;
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/');
  }
  return <>
    <div className={styles.wrapper}>
      <div className={styles.back} onClick={handleBack}>
        <img src="/back.png" alt="חזור" height='35px'/>
      </div>
      <Carousel>
        {item.src.map((src) => {
          return <Carousel.Item key={src} interval={3000}>
            <img
              className="d-block w-100"
              src={src}
              alt={item.title}
            />
          </Carousel.Item>
        })}
      </Carousel>
    </div>
  </>
}
export default ItemCarousel;