import  { ReactElement } from "react";
import styles from './SmallContact.module.css'


const SmallContact = (): ReactElement => {
  return <div className={styles.SmallContact}>
    <div>
       מוזמנים ליצור קשר בטלפון הבא: 0549073313
    </div>
      <div>
        <div>יצירת קשר דרך הוואצפ - לחצו </div>
        <a
          href="https://wa.me/972549073313"
          target='_blank'
          rel="noreferrer"
        >
          <img src='/whatsapp.png' alt='whatsapp' height='25px' />
        </a>
      </div>
      <div>
        <div>לשיחת טלפון - לחצו</div>
        <a href="tel:0549073313"><img src='/telephone.png' alt='phone' height='25px' /></a>
      </div>
  </div>
}
export default SmallContact;