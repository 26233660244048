import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import database, { IItem } from "../../database/items";
import styles from "./Item.module.css"
import VerticalLine from "../../components/VerticalLine/VerticalLine";
import OrderDescription from "../../components/OrderDescription/OrderDescription";
import ItemCarousel from "../../components/ItemCarousel/ItemCarousel";

const Item = (): ReactElement => {
  const [item, setItem] = useState<IItem | null>();
  const { itemId } = useParams();
  useEffect(() => {
    let foundItem;
    database['purim'].forEach((item) => { if (item.id === itemId) foundItem = item })
    if (foundItem)
      setItem(foundItem);
    else
      setItem(null);
  }, [itemId])


  if (!item) {
    return <div></div>
  }

  return <div className={styles.Item}>
    {/* <div className={styles.Back} onClick={handleBack}>
    <span>לחזרה</span>
      <img src='/back.png' alt='חזרה' height="20" />
    </div> */}
    <ItemCarousel item={item} />
    <VerticalLine />
    <div className={styles.Content}>
      <div className={styles.Title}>
        {item.title}
      </div>
      <div className={styles.Price}>
        {item.price} ₪
      </div>
      <VerticalLine />
      <div className={styles.Description}>
        {item.description.split("\n").map((value, index) => {
          return (
            <span key={index}>
              {value}
              <br />
            </span>
          )
        })
        }
      </div>
      <VerticalLine />
      <OrderDescription />
    </div>

  </div>

}
export default Item;