import { ReactElement } from "react";
import { IItem } from "../../database/items";
import styles from "./PageItem.module.css"
interface IProps {
  item: IItem;
}

const PageItem = (props: IProps): ReactElement => {
  const { item: { title, src, price } } = props;
  return <div className={styles.pageItem}>
      <div className={styles.imageContainer}>
        <img className={styles.image} alt={title} src={src[0]} />
      </div>
      <div className={styles.description}>
        <div>{title}</div>
        <div>{price} ש״ח</div>
      </div>
  </div>
}
export default PageItem;