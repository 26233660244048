import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
  let navigate = useNavigate();
  const handleLogoClick = () => {
    navigate('/');
  }
  return <div className={styles.navbar}>
      <div className={styles.logo} onClick={handleLogoClick}>
        Lior Ben Zikri
      </div>
    </div>

}
export default Navbar;