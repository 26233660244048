import { ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Item from "../../screens/Item/Item";
import Page from "../../screens/Page/Page";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import styles from "./Application.module.css";

const Application = (): ReactElement => {
  return <div className={styles.application}>
    <Navbar />
    <div className={styles.content}>
      <Routes>
        <Route path="/" element={<Page/>} />
        <Route path="/item/:itemId" element={<Item/>}/>
        <Route
          path="*"
          element={<Navigate to="/" />}
        />
      </Routes>
    </div>
    <Footer/>
  </div>
}
export default Application;