import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return <div>
    <div className={styles.Footer}>
      כל הזכויות שמורות ® Lior Ben Zikri
    </div>
    </div>
}
export default Footer;